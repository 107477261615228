// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Layout from "../shared/Layout.re.mjs";
import * as PageTitle from "../vanillaui/atoms/PageTitle.re.mjs";
import * as RatingList from "../organisms/RatingList.re.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as WaitForMessages from "../shared/i18n/WaitForMessages.re.mjs";
import * as ReactRouterDom from "react-router-dom";
import * as JsxRuntime from "react/jsx-runtime";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.re.mjs";
import * as LeagueRankingsPageQuery_graphql from "../../__generated__/LeagueRankingsPageQuery_graphql.re.mjs";

import { css, cx } from '@linaria/core'
;

import { t } from '@lingui/macro'
;

var convertVariables = LeagueRankingsPageQuery_graphql.Internal.convertVariables;

var convertResponse = LeagueRankingsPageQuery_graphql.Internal.convertResponse;

RescriptRelay_Query.useQuery(convertVariables, LeagueRankingsPageQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, LeagueRankingsPageQuery_graphql.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(LeagueRankingsPageQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(LeagueRankingsPageQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(LeagueRankingsPageQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(LeagueRankingsPageQuery_graphql.node, convertVariables);

function LeagueRankingsPage(props) {
  var query = ReactRouterDom.useLoaderData();
  var match = usePreloaded(query.data.query);
  var fragmentRefs = match.fragmentRefs;
  return JsxRuntime.jsx(WaitForMessages.make, {
              children: (function () {
                  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsxs(Layout.Container.make, {
                                            children: [
                                              JsxRuntime.jsx(PageTitle.make, {
                                                    children: JsxRuntime.jsx("span", {
                                                          children: t`Recreational Doubles`,
                                                          className: "text-white font-extrabold text-3xl"
                                                        })
                                                  }),
                                              JsxRuntime.jsx("p", {
                                                    children: t`Play doubles games with different partners and receive an individual rating. Prizes are awarded monthly to top players.`,
                                                    className: "mt-5"
                                                  }),
                                              JsxRuntime.jsxs("p", {
                                                    children: [
                                                      t`To participate, please join the events here:`,
                                                      " ",
                                                      JsxRuntime.jsx("a", {
                                                            children: "> Racquet League <",
                                                            className: "text-gray-200",
                                                            href: "https://www.racquetleague.com"
                                                          })
                                                    ]
                                                  })
                                            ]
                                          }),
                                      className: "py-10 text-white bg-leaguePrimary"
                                    }),
                                JsxRuntime.jsx(React.Suspense, {
                                      children: Caml_option.some(JsxRuntime.jsx(RatingList.make, {
                                                ratings: fragmentRefs
                                              })),
                                      fallback: Caml_option.some(JsxRuntime.jsx(Layout.Container.make, {
                                                children: t`Loading rankings...`
                                              }))
                                    })
                              ]
                            });
                })
            });
}

var make = LeagueRankingsPage;

export {
  make ,
}
/*  Not a pure module */
